.FeaturesSection {
  &__item {
    padding: 20px;
  }

  &__item_content {
    font-size: 0.9em;
    height: 160px;
    margin-left: -10px;
    margin-right: -10px;
  }

  &__active {
    background-color: var(--jasmine);
  }

  &__inactive {
    background-color: var(--celeste);
    opacity: 0.5;
  }

  &__image-container {
    margin: 0 auto;
    max-width: 400px;
    margin-bottom: 20px;
  }
}
