.ProjectsSection {
  &__past_project {
    background-color: #F0F0F0;
    opacity: 0.7;
  }

  &__title {
    font-size: 1.1em;
    background-color: var(--jasmine);
    padding: 10px 20px 10px 20px;
    width: 100%;
    align-items: center;
  }

  &__description1 {
    --lh: 1.4rem;
    --max-lines: 4;

    font-size: 0.9em;
    padding: 5px;
    width: 100%;
  }

  &__display-linebreak {
    white-space: pre-line;
  }

  &__description {
    --lh: 1.4rem;
    --max-lines: 6;
    font-size: 0.9em;
    padding: 5px;
    width: 100%;

    white-space: pre-line;

    // following allows for showing only select # lines
    display: -webkit-box;
    -webkit-line-clamp: var(--max-lines);
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__description_detail {
    padding: 5px;
    width: 100%;
    white-space: pre-line;
  }

  &__footer {
    font-size: 0.9em;
  }

  /* overflow does line clamping; but always shows ... which is not great. */
  &__overflow {
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem; /* space for ellipsis */
}
  &__overflow::before {
    position: absolute;
    content: "...";
  /*   inset-block-end: 0;
    inset-inline-end: 0; */
    bottom: 0;
    right: 0;
  }
  &__overflow::after {
    content: "";
    position: absolute;
  /*   inset-inline-end: 0; */
    right: 0;
    width: 1rem;
    height: 1rem;
    background: white;
  }


  background-color: var(--post-it-note);
}
