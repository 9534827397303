@charset "utf-8";


/*
// SparkSIP coolors are:
https://coolors.co/4b3ddc-fee0be-e3b238-e84855-e44044
*/


:root {

  // https://coolors.co/e54045-f4b624-b1ede8-352d39
  --imperial-red: #E54045;
  --selective-yellow: #E3B238;
  --black-coffee: #352D39; // good dark color for fonts
  --spanish-viridian: #0C7C59; // for buttons?

  // Lighther colors
  --celeste: #B1EDE8;
  --jasmine: #FFE085; // for post-it-notes
  --lite-yellow: #FEFADC;
  --bisque: #FEE0BE;
  --light-cyan: #DEF7F5; // for highlight background

  // configured colors for site usage
  --site-1-color: var(--imperial-red);
  --site-2-color: var(--selective-yellow);

  --site-font-color: var(--black-coffee);
  --site-background-color: var(--celeste);

  --post-it-note: var(--jasmine);

  background-color: var(--site-background-color) !important;

}


// colors from myYogaTeacher.com
// #4B3DDC - Majorelle Blue
// #09B2C8 - Pacific Blue
// #02DFA0 - Medium Aquamarine


// COLORS
$theme-colors: (
  "primary": #0074d9,
  "secondary": #0C7C59, // #6c757d,
  "success": #E54045, // 0C7C59, /* #28a745, */
  "danger": #ff4136,
  "warning": #E54045, //  #ffc107,
  "info": #E3B238, // #17a2b8,
  "light": #f8f9fa,
  "dark": #343a40,

  "silver": silver,
  "white": #ffffff,
  "bisque": #FEE0BE,
  "celeste": #B1EDE8,
  "lite-yellow": #FEFADC,
  "transparent": transparent,
);


// FONT Selection
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/raleway/v19/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCFPrcVIT9d0c-dYA.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

// TEXT
$font-family-sans-serif: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: 'Raleway', Georgia, "Times New Roman", Times, serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// SEE DOCS FOR MORE:
// https://bit.ly/2sgFMdb

// IMPORT BOOTSTRAP
@import "~bootstrap/scss/bootstrap";

// IMPORT FONT AWESOME
@import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");


#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }
}
