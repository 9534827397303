
.TimelineSection {
  font-size: 0.8em;
  padding: 20px;
  min-width: 360px;

  &__events {
    padding-top: 10px;
  }

  &__active {
    background-color: var(--jasmine);
  }

  &__actions {
    border-left: 6px dashed green;
    height: 80px;
    margin-left: -3px;
  }
}
