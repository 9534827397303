.SectionHeader {
  // Add bottom margin if element below
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__fh1 {
    font-size: 2.5rem;
    margin-top: 10px !important;
  }

  &__fh3 {
    font-size: 1.7rem;
  }

  &__fh5 {
    font-size: 1.3rem;
  }

  &__subtitle {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    max-width: 800px;
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: inline-block;
    font-size: 1.4em;
    color: black !important;
  }
}
