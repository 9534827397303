.PricingSection {
  &__active_price {
  }
  &__inactive_price {
    opacity: 0.5;
    font-size: 0.9rem;
  }
  &__period {
    opacity: 0.4;
  }
  &__pricing_header {
    min-height: 160px;
  }
  &__pricing_perks {
    min-height: 180px;
  }
  &__discount {
    text-decoration: underline;
    background-color: var(--jasmine);
  }
}
