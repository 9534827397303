.DashboardSection {
  &__image-container {
    max-width: 370px;
  }

  &__Student {
    background-color: var(--lite-yellow);
  }

  &__Business {
    background-color: var(--bisque);
  }

  &__subscribed {
    background-color: var(--lite-yellow);
  }
}
